<template>
  <modal-box
    :is-active.sync="isActive"
    @confirm="confirm"
    @cancel="cancel"
    confirm-type="danger"
    confirm-label="Hapus"
  >
    <p>
      Apakah anda yakin akan menghapus
      <b class="text-uppercase">{{ trashSubject }}</b>
    </p>
  </modal-box>
</template>

<script>
import ModalBox from "./ModalBox";
export default {
  name: "ModalTrashBox",
  components: { ModalBox },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    trashSubject: {
      type: String,
      default: null,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>
