<template>
  <v-dialog tile persistent v-model="dialog" max-width="400">
    <v-card tile class="pa-2 white">
      <v-card-title class="h5 justify-center" v-text="title" />
      <v-card-text class="black--text">
        <slot />
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          depressed
          tile
          small
          color="#ddd"
          class="black--text col"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          tile
          small
          color="red"
          class="white--text col"
          @click="confirm"
        >
          {{ confirmLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalBox",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    confirmLabel: {
      type: String,
      default: "Confirm",
    },
    confirmType: {
      type: String,
      default: "is-info",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    confirmButtonClass() {
      return `${this.confirmType}`;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
  watch: {
    isActive(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      if (!newValue) {
        this.cancel();
      }
    },
  },
};
</script>
